// Function to get URL parameters
function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// Handle UTM parameter persistence
function handleUtmParameter() {
  const utmParams = ['utm_source', 'utm_medium'];
  
  utmParams.forEach(param => {
    const value = getUrlParameter(param);
    if (value) {
      // Save to sessionStorage if UTM is present in URL
      sessionStorage.setItem(param, value);
    }
  });
}

// Initialize
document.addEventListener('DOMContentLoaded', function() {
  handleUtmParameter();
});